import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    overflow: 'hidden',
    backgroundColor: 'background.white',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'border.main',
    borderRadius: '6px',
    marginBottom: 2,
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  group: {
    width: '100%',
  },
  headerWrapper: {
    width: '100%',
    backgroundColor: 'background.gray',
    paddingY: 1.5,
    paddingX: 2,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'relative',
  },
  totalHours: {
    marginRight: 10.125,
    marginLeft: 'auto',
  },
  collapseIcon: {
    color: 'button.main',
    marginRight: 1,
  },
  checkbox: {
    padding: 0.5,
    marginRight: 1,
  },
};

export default styles;
