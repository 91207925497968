import { SxStyles } from 'theme';

const styles: SxStyles = {
  visibleContent: {
    display: 'flex',
    justifyContent: 'flex',
    alignItems: 'center',
    borderTop: theme => `1px solid ${theme.palette.border.main}`,
  },
  visibleContentWithBackground: {
    backgroundColor: 'background.lightGray',
  },
  expand: {
    width: '5%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  date: {
    width: '10%',
    padding: 2,
    color: 'text.gunmetalGrey',
  },
  resource: {
    width: '25%',
    padding: 2,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  specialization: {
    width: '20%',
    padding: 2,
    color: 'text.gunmetalGrey',
  },
  vacationRange: {
    width: '20%',
    padding: 2,
    color: 'text.gunmetalGrey',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  buttons: {
    width: '20%',
    padding: 2,
    display: 'flex',
  },
  userName: {
    width: '100%',
    color: 'primary.blue',
    textDecoration: 'none',
  },
  avatar: {
    width: '24px',
    height: '24px',
    marginRight: 1,
  },
  range: {
    color: 'text.blackLight',
  },
  days: {
    marginLeft: 1.5,
    color: 'text.lightGray',
  },
  cancel: {
    padding: 0,
    color: 'primary.red',
    marginLeft: 'auto',
  },
  icon: {
    width: '16px',
    height: '16px',
    marginRight: 1,
  },
  approve: {
    padding: 0,
    color: 'text.green',
  },
  expandButton: {
    width: '24px',
    height: '24px',
  },
  expandIcon: {
    color: 'button.main',
  },
};

export default styles;
