import { TagTypes, provideTags } from 'shared/api/types';

import { ConditionState } from 'components/common/SmartFilter/enums';

import { apiRoutes } from 'routes';

import { apiContainer } from 'store';

import { IMeta } from 'types/meta';
import { ISortParameter } from 'types/runsack';

import { IReportVacationResource } from './types';

interface IGetReportVacationsResponse {
  resources: Array<IReportVacationResource>;
  meta: IMeta;
}

export interface IReportVacationsSmartFilters {
  groupIdsIn?: Array<number>;
  projectIdsIn?: Array<number>;
  resourceIdsIn?: Array<number>;
  stateEq?: ConditionState;
  technologyIdsIn?: Array<number>;
  startDate: string;
  endDate: string;
}

export interface IGetReportVacationsParameters extends IReportVacationsSmartFilters {
  page?: number;
  perPage?: number;
  sort?: ISortParameter;
}

export const reportVacationApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getReportVacations: builder.query<IGetReportVacationsResponse, IGetReportVacationsParameters>({
      query: parameters => {
        return {
          url: apiRoutes.apiV1ReportsVacationsPath(),
          method: 'GET',
          params: {
            ...parameters,
          },
        };
      },
      providesTags: response => provideTags(TagTypes.ReportVacation, response?.resources),
    }),
  }),
  overrideExisting: false,
});

export const { useGetReportVacationsQuery } = reportVacationApi;
