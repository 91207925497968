import { useMemo } from 'react';

import { Tab, Tabs, Typography } from '@mui/material';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import { useTranslation } from 'react-i18next';

import { useRouter } from 'hooks';

import { DateRange } from 'components/common/DateRangePicker/types';
import Div from 'components/common/Div';
import ReportFiltersButton from 'components/common/ReportFiltersButton';
import SmartFilter from 'components/common/SmartFilter';
import { ISmartFilter } from 'components/common/SmartFilter/types';
import TabPanel from 'components/common/TabPanel';

import { useGetCurrentUserQuery } from 'domain/currentUser/apiSlice';
import { ReportType } from 'domain/report/filter/enums';
import { IReportFilter } from 'domain/report/filter/types';
import { IReportVacationsSmartFilters } from 'domain/report/vacation/apiSlice';
import { IReportVacationRequestsSmartFilters } from 'domain/report/vacationRequest/apiSlice';

import { VacationsTab } from 'enums/reportVacations';

import { usePermissions } from 'hooks/usePermissions';

import { dateToString } from 'utils/calendar';

import Calendar from './components/Calendar';
import VacationRequests from './components/VacationRequests';
import { buildFilterValues, getQueryString } from './service';
import styles from './styles';

const FIRST_WEEK_DAY = 1;
const VACATION_TAB_LABEL = 'vacationsInfo';
const REPORT_TYPE = ReportType.trackedTimeEntries;
const FILTERS: Array<ISmartFilter> = ['resource', 'project', 'technology', 'group', 'state'];
const VACATION_REQUEST_FILTERS: Array<ISmartFilter> = [
  'resource',
  'project',
  'technology',
  'group',
  'vacationRequestState',
];

const Vacations = () => {
  const { data: currentUserData } = useGetCurrentUserQuery();

  const { t } = useTranslation('reportVacations');
  const { isRoleFeatureAllowed } = usePermissions();
  const { queryStringUpdate, camelizedQuery } = useRouter();

  const weekStartsDay = currentUserData?.user?.weekStartsDay ?? FIRST_WEEK_DAY;

  const filterState = useMemo(
    () => buildFilterValues(camelizedQuery, weekStartsDay),
    [getQueryString(camelizedQuery), weekStartsDay],
  );

  const { activeTab, ...filter } = filterState;
  const isVacationRequestsActive = activeTab === VacationsTab.vacationRequests;

  const vacationRequestFilter = omit(filter, ['stateEq', 'startDate', 'endDate']);
  const vacationCalendarFilter = omit(filter, ['vacationRequestStateEq']);

  const filterKeys = isVacationRequestsActive ? VACATION_REQUEST_FILTERS : FILTERS;
  const smartFilter = isVacationRequestsActive
    ? vacationRequestFilter
    : omit(vacationCalendarFilter, ['startDate', 'endDate']);

  const handleTabChange = (_: React.SyntheticEvent, newActiveTab: VacationsTab) => {
    const newParameters = {
      activeTab: newActiveTab,
      ...filter,
    };

    queryStringUpdate(newParameters);
  };

  const handleSmartFiltersChange = (
    newFilters: IReportVacationsSmartFilters | IReportVacationRequestsSmartFilters | null,
  ) => {
    if (isNil(newFilters)) return;

    const { startDate, endDate, stateEq, vacationRequestStateEq } = filterState;
    const parameters = {
      activeTab,
      startDate,
      endDate,
      ...newFilters,
      ...(isVacationRequestsActive ? { stateEq } : { vacationRequestStateEq }),
    };

    queryStringUpdate(parameters);
  };

  const handleFiltersChange = (reportFilters: IReportFilter | null) => {
    if (isNil(reportFilters)) return;

    const newFilters = JSON.parse(reportFilters.filter);
    const parameters = {
      ...filterState,
      ...newFilters,
    };

    queryStringUpdate(parameters);
  };

  const handleDateRangeChange = (dateRange: DateRange) => {
    if (isNil(dateRange)) return;

    const newParameters = {
      ...filterState,
      activeTab,
      startDate: dateToString(dateRange.startDate),
      endDate: dateToString(dateRange.endDate),
    };

    queryStringUpdate(newParameters);
  };

  return (
    <>
      <Div sx={styles.titleBlock}>
        <Typography variant="h1">{t('title')}</Typography>
        <ReportFiltersButton onChange={handleFiltersChange} currentReportType={REPORT_TYPE} />
      </Div>

      <Div sx={styles.smartFilter}>
        <SmartFilter<IReportVacationsSmartFilters | IReportVacationRequestsSmartFilters>
          filters={filterKeys}
          smartFilter={smartFilter}
          onSmartFilterChange={handleSmartFiltersChange}
          reportType={REPORT_TYPE}
        />
      </Div>

      {isRoleFeatureAllowed('canViewVacationsReport') ? (
        <Div sx={styles.container}>
          <Div sx={styles.tabHeadersContainer}>
            <Tabs value={activeTab} onChange={handleTabChange} aria-label="tabs" textColor="inherit">
              <Tab
                id={`tab${t('tabs.vacationRequests.label')}`}
                label={t('tabs.vacationRequests.label')}
                aria-controls={`tabpanel${VACATION_TAB_LABEL}`}
                value={VacationsTab.vacationRequests}
              />
              <Tab
                id={`tab${t('tabs.calendar.label')}`}
                label={t('tabs.calendar.label')}
                aria-controls={`tabpanel${VACATION_TAB_LABEL}`}
                value={VacationsTab.calendar}
              />
            </Tabs>
          </Div>
          {isVacationRequestsActive && (
            <TabPanel value={activeTab} name={VacationsTab.vacationRequests} tabGroupName={VACATION_TAB_LABEL}>
              <VacationRequests smartFilter={vacationRequestFilter} />
            </TabPanel>
          )}
          {!isVacationRequestsActive && (
            <TabPanel value={activeTab} name={VacationsTab.calendar} tabGroupName={VACATION_TAB_LABEL}>
              <Calendar filters={vacationCalendarFilter} onDateRangeChange={handleDateRangeChange} />
            </TabPanel>
          )}
        </Div>
      ) : (
        <Typography textAlign="center">{t('common:noAccessToData')}</Typography>
      )}
    </>
  );
};

export default Vacations;
