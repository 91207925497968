import { FC } from 'react';

import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { Button, Divider, Typography } from '@mui/material';
import { isSameDay } from 'date-fns';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';

import { useDeleteResourceVacationMutation } from 'domain/resource/vacation/apiSlice';
import { isVacationResourceApproved, isVacationResourceDeclined } from 'domain/resource/vacation/service';

import { useNotifications } from 'hooks/useNotifications';
import { usePermissions } from 'hooks/usePermissions';

import { getVacationDaysCount, stringToDate, toOnApprovalRange } from 'utils/calendar';
import { generateBackendErrorMessages } from 'utils/error';

import styles from './styles';
import { IVacationProps } from './types';

const Vacation: FC<IVacationProps> = props => {
  const { vacation, resourceId, daysInfo } = props;
  const { t } = useTranslation('userProfile');
  const { showSuccessNotification, showErrorNotification } = useNotifications();
  const { isRoleFeatureAllowed } = usePermissions();
  const canApproveVacation = isRoleFeatureAllowed('canApproveVacation');

  const [deleteResourceVacation, { isLoading: isDeleteResourceVacationLoading }] = useDeleteResourceVacationMutation();

  const startDate = stringToDate(vacation.startDate);
  const endDate = stringToDate(vacation.endDate);

  const vacationDaysCount = getVacationDaysCount(daysInfo, vacation.startDate, vacation.endDate);
  const isVacationOneDay = isSameDay(startDate, endDate);

  const handleDeleteResourceVacation = async () => {
    try {
      await deleteResourceVacation({ id: vacation.id, resourceId }).unwrap();
      showSuccessNotification();
    } catch (error) {
      const errors = generateBackendErrorMessages(error);
      for (const message of errors) {
        showErrorNotification(message);
      }
    }
  };

  return (
    <Div>
      <Div sx={styles.header}>
        <Typography variant="heading3" sx={styles.range}>
          {isVacationOneDay
            ? toOnApprovalRange(startDate)
            : t('vacation.onApprovalRange', { start: toOnApprovalRange(startDate), end: toOnApprovalRange(endDate) })}
        </Typography>
        <Typography variant="body2" sx={styles.days}>
          {t('vacation.days', { days: vacationDaysCount })}
        </Typography>
      </Div>
      {(isVacationResourceApproved(vacation) || isVacationResourceDeclined(vacation)) && (
        <>
          {canApproveVacation && (
            <Div sx={styles.buttons}>
              <Button
                onClick={handleDeleteResourceVacation}
                disabled={isDeleteResourceVacationLoading}
                variant="text"
                sx={styles.cancel}
              >
                <CancelOutlinedIcon sx={styles.icon} />
                {t('vacation.cancel')}
              </Button>
            </Div>
          )}
          <Divider sx={styles.divider} />
        </>
      )}
    </Div>
  );
};

export default Vacation;
