import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    paddingRight: 2,
  },
  title: {
    marginBottom: 4,
    paddingTop: 4,
  },
  header: {
    position: {
      mobile: 'relative',
      desktop: 'sticky',
    },
    zIndex: '1',
    backgroundColor: 'background.default',
    top: {
      desktop: '-2em',
    },
  },
};

export default styles;
